import { PRODUCTS_VIEW_TYPE } from '@/helpers';
import ProductsSortSelect from '@/components/products/ProductsBlock/ProductsSortSelect/index.vue';
import ProductCard from '@/components/cards/ProductCard/index.vue';
import ProductsSidebarFilters from '@/components/products/ProductsBlock/ProductsSidebarFilters/index.vue';
import { MEDIA } from '@/helpers/variables';
import { mapGetters } from 'vuex';
import { storage } from '@/utils/storage';

export default {
  name: 'ProductsBlock',
  props: {
    viewKey: {
      type: String,
      default: 'products_block_view'
    },
    isSmallTittle: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    sortTypeDefault: {
      type: [String, null],
      default: null
    },
    isHasFilters: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  emits: [],
  data() {
    return {
      PRODUCTS_VIEW_TYPE,
      MEDIA,
      isOpenSidebarFilters: false,
      viewType: PRODUCTS_VIEW_TYPE.GRID
    };
  },
  components: { ProductsSidebarFilters, ProductCard, ProductsSortSelect },
  created() {
    let viewType = storage.getItem(this.viewKey);
    if (viewType && Object.values(PRODUCTS_VIEW_TYPE).find((item) => item === viewType)) {
      this.viewType = viewType;
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      appSize: 'app/size',
      filters: 'selection/filters'
    })
  },
  methods: {
    onChangeView(type) {
      storage.setItem(this.viewKey, type);
      this.viewType = type;
    }
  }
};
