import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

//---helpers
import { PRODUCTS_ORDER_KEY } from '@/helpers';
import { globalLoader } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';
import ProductsBlock from '@/components/products/ProductsBlock/index.vue';

export default {
  name: 'Ingredient',
  mixins: [dynamicHead],
  components: {
    ProductsBlock,
    BreadcrumbsSimple
  },
  data() {
    return {
      ingredientData: null,
      ingredientProducts: [],
      ingredientProductsMeta: null,
      isIngredientProductsLoading: false
    };
  },
  created() {
    globalLoader(true);
    this._loadContent();
  },
  watch: {
    $route: {
      immediate: false,
      handler(newVal) {
        if (newVal) {
          this._getProducts();
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      favourites: 'favorites/whichList'
    }),
    breadcrumbs() {
      return [{ title: this.$t('page.ingredients.title'), route: { name: 'ingredients' } }];
    },
    productsList() {
      if (!this.isAuthenticated || !this.favourites) {
        return this.ingredientProducts;
      }

      const favourites = _.map(this.favourites, 'productId');
      const markAsSelected = (product) => {
        product.isFavorite = favourites.includes(product.id);
        return product;
      };

      return _.map(this.ingredientProducts, markAsSelected);
    }
  },
  methods: {
    ...mapActions({
      getIngredientData: 'ingredients/GET_INGREDIENT',
      getCatalogProducts: 'catalog/GET_CATALOG_PRODUCTS'
    }),
    async _getProducts() {
      this.isIngredientProductsLoading = true;
      let { params: routeParams, query } = this.$route;
      let { slug, page } = routeParams;
      let { orderby } = query;
      return this.getCatalogProducts({
        ingredient: slug,
        page: page ? Number(page) : 1,
        sort_filter_type: orderby ? orderby : PRODUCTS_ORDER_KEY.BESTSELLERS
      })
        .then((result) => {
          this.ingredientProducts = result.data;
          this.ingredientProductsMeta = result.meta;
        })
        .finally(() => {
          this.isIngredientProductsLoading = false;
        });
    },

    async _loadContent() {
      let promiseCollection = [];
      let { slug } = this.$route.params;
      promiseCollection.push(this.getIngredientData(slug).then((result) => (this.ingredientData = result.data)));
      promiseCollection.push(this._getProducts());
      await Promise.all(promiseCollection);

      this._setMetaParams();
      this.setMeta();
      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, title, metaDescription, image } = this.ingredientData;
      if (title || metaTitle)
        this.metaParams.title = `${metaTitle ? metaTitle : title}${this.$t('seo.metaTitleGeneral')}`;

      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
    }
  }
};
